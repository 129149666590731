<template>
  <v-row
    :id="block_id"
    v-click-outside="triggerOnFocus"
  >
    <v-col cols="12">
      <v-row class="mt-0">
        <v-col class="pt-4 pr-0 mr-4 d-flex align-center">
          <slot name="navigation" />
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
              >
                mdi-tune
              </v-icon>
            </template>
            <v-list>
              <v-list-item
                :disabled="disabledView"
                @click="createWare"
              >
                Добавить товар
              </v-list-item>
              <v-list-item
                :disabled="disabledView"
                @click="confirmRemoveWare"
              >
                Удалить товар
              </v-list-item>
              <v-list-item
                :disabled="disabledView"
                @click="copyItem"
              >
                Копировать товар
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col
          cols="2"
          class="d-flex align-center pt-4"
        >
          <v-text-field
            v-model="item.tn_ved"
            v-mask="'##########'"
            :disabled="disabledView"
            class="mr-2"
            outlined
            dense
            hide-details
            background-color="white"
            @keypress.enter="showTNVED"
            @change="tnvedChanged"
          />
          <v-btn
            small
            :class="{ 'teal lighten-2': tab === 0 }"
            max-width="30px"
            min-width="30px"
            text
            @click="toggleTreeView"
          >
            <v-icon> mdi-file-tree-outline </v-icon>
          </v-btn>
          <v-btn
            small
            max-width="30px"
            min-width="30px"
            text
            @click="showCodeInfo(item.tn_ved)"
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="1"
          class="d-flex align-center px-0 pt-4"
        >
          <v-text-field
            v-model="item.add_tn_ved"
            v-mask="'####'"
            :disabled="disabledView"
            background-color="white"
            outlined
            dense
            hide-details
            @keypress.enter="showExtraTNVED"
          />
          <tnved-tooltip :item="item" />
        </v-col>
        <v-col
          class="border-sm"
          cols="2"
        >
          <div class="bordered d-flex justify-center px-1">
            <div
              v-for="checkbox in xsdUpdate ? checkboxes : checkboxes_old"
              :key="checkbox.model"
            >
              <label
                class="font-weight-bold teal--text cursor-pointer d-block px-1"
              >{{ checkbox.label }}</label>

              <v-checkbox
                v-model="item[checkbox.model]"
                :disabled="disabledView"
                dense
                hide-details
                color="black"
                @change="readyToUpdate"
              />
            </div>
            <div
              v-if="xsdUpdate"
              style="width:40%;"
            >
              <label class="font-weight-bold teal--text cursor-pointer d-block px-1">
                ИС
              </label>
              <v-autocomplete                  
                ref="declaration_kind_code"
                v-model="item.intellectual_code"
                auto-select-first
                outlined
                hide-details="auto"
                dense
                background-color="white"                  
                :items="['I', 'F']"
                class="mr-1 p-0"
                @change="intellectualCodeChange"
              />
            </div>
          </div>
        </v-col>
        <v-col
          cols="2"
          class="pt-0"
        >
          <label>34 Страна происх-я</label>
          <div class="d-flex">
            <v-autocomplete
              v-model="item.origin_country_letter"
              :disabled="disabledView"
              auto-select-first
              outlined
              dense
              hide-details="auto"
              background-color="white"
              class="mr-2"
              :items="countries"
              item-text="text"
              item-value="letterCode"
              :filter="filterBySearchField"
            />
            <v-autocomplete
              v-model="item.origin_country_letter_b"
              :disabled="disabledView"
              auto-select-first
              outlined
              dense
              hide-details="auto"
              background-color="white"
              class="mr-2"
              :items="countries"
              item-text="text"
              item-value="letterCode"
              :filter="filterBySearchField"
            />
          </div>
        </v-col>
        <v-col
          cols="3"
          class="pt-0"
        >
          <label>Преференции</label>
          <div class="d-flex">
            <v-autocomplete
              v-model="item.preference_1"
              :disabled="disabledView"
              :items="preferences_1"
              item-text="code"
              item-value="code"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              class="mr-2"
              auto-select-first
            />
            <v-autocomplete
              v-model="item.preference_2"
              :disabled="disabledView"
              :items="preferences_2"
              item-text="code"
              item-value="code"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              class="mr-2"
              auto-select-first
            />
            <v-autocomplete
              v-model="item.preference_3"
              :disabled="disabledView"
              :items="preferences_3"
              item-text="code"
              item-value="code"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              class="mr-2"
              auto-select-first
            />
            <v-autocomplete
              v-model="item.preference_4"
              :disabled="disabledView"
              :items="preferences_4"
              item-text="code"
              item-value="code"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              auto-select-first
            />
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      class="py-0"
      cols="12"
    >
      <ware-dynamic-tnved-tabs
        v-model="tab"
        class="py-0"
        :class="{ 'bordered pt-4': tab !== null }"
        :tabs="tabs"
        :code="latestCode"
      />
    </v-col>
    <v-col cols="12">
      <div class="textarea-border-solid mb-4 position-relative">
        <v-textarea
          v-model.trim="item.description"
          :disabled="disabledView"
          :counter="1000"
          no-resize
          :counter-value="total"
          loading="false"
          auto-grow
          rows="1"
          dense
          hide-details="auto"
          :error="totalValue > 1000"
          background-color="white"
        />
        <description-menu
          :value="item.description"
          field="description"
        />
      </div>

      <div class="textarea-border-solid position-relative">
        <v-textarea
          v-model.trim="item.specific_description"
          :disabled="disabledView"
          :counter="1000"
          :counter-value="total"
          auto-grow
          loading="false"
          placeholder="Специальное описание товара"
          rows="1"
          dense
          hide-details="auto"
          :error="totalValue > 1000"
          background-color="white"
        />
        <description-menu
          :value="item.specific_description"
          field="specific_description"
        />
      </div>
    </v-col>
    <v-col cols="6">
      <v-text-field
        v-model="item.manufacturer"
        :disabled="disabledView"
        outlined
        placeholder="Производитель отсутствует"
        dense
        hide-details="auto"
        background-color="white"
      />
    </v-col>
    <v-col cols="4">
      <v-text-field
        v-model="item.trade_mark"
        :disabled="disabledView"
        outlined
        placeholder="Товарный знак отсутствует"
        dense
        hide-details="auto"
        background-color="white"
      />
    </v-col>
    <v-col cols="2">
      <v-autocomplete
        v-model="item.details_measure_unit_digit"
        :disabled="disabledView"
        :items="measureUnits"
        item-text="shortDescription"
        placeholder="Ед. изм."
        item-value="code"
        auto-select-first
        outlined
        dense
        hide-details="auto"
        :filter="filterBySearchField"
        background-color="white"
      />
    </v-col>
    <!-- <v-col
      cols="4"
      align-self="center"
    >
      <slot name="types" />
    </v-col> -->
    <component
      :is="activeComponent"
      :ref="getReferenceAsync"
      :show.sync="show"
    />
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import blockAutoUpdateMixin from "@/mixins/block-auto-update.mixin";
import {
  getCountryNameByCode,
  getMeasureUnitShortName,
} from "@/helpers/catalogs";
import { showCatalog } from "@/events/statistics";
import { eventBus } from "@/bus";
import moment from "moment";
import TnvedTooltip from "@/components/kdt/goods/tnved-tooltip.vue";
import DescriptionMenu from "@/components/kdt/goods/description-menu.vue";
import { showCodeInfo } from "@/helpers/tnved";
import WareDynamicTnvedTabs from "@/components/documents/ware/ware-dynamic-tnved-tabs.vue";
import ConfirmAction from "@/components/shared/confirm-action.vue";
import {
  getReferenceAsync,
  waitAsyncDecision,
  waitResultOrDie,
} from "@/helpers/async-component";
const WD_TNVED = "ware-detail-good-item-tnved";
const WD_ADD_TNVED = "ware-detail-good-item-tnved-event";
export default {
  components: { WareDynamicTnvedTabs, DescriptionMenu, TnvedTooltip },
  mixins: [filterBySearchFieldMixin, blockAutoUpdateMixin],
  props: {
    id: {
      required: true,
      type: Number,
    },
    sortIndex: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      block_id: "ware-details-modal",
      activeComponent: null,
      show: false,
      asyncDecision: {
        resolve: null,
        reject: null,
      },
      tab: null,
      tabs: [
        {
          title: "Показать структуру",
          id: 1,
          component: () => import("@/components/tnved/structure.vue"),
        },
      ],
      latestCode: null,
      stopPropagation: true,
      promises: [],
      is_goods_item: false,
      checkboxes_old:[
        {
          model:"under_ban",
          label:"С"
        },
        {
          model:"intellectual_property",
          label:"ИС"
        },
        {
          model:"marking",
          label:"М"
        },
        {
          model:"traceability",
          label:"П"
        },
      ],
      checkboxes:[
        {
          model:"under_ban",
          label:"С"
        },
        {
          model:"marking",
          label:"М"
        },
        {
          model:"traceability",
          label:"П"
        },
      ],
      item: {
        tn_ved: null,
        description: null,
        specific_description: null,
        manufacturer: null,
        trade_mark: null,
        add_tn_ved: null,
        origin_country_letter: null,
        origin_country_letter_b: null,
        preference_1: null,
        preference_2: null,
        preference_3: null,
        preference_4: null,
        under_ban: false,
        intellectual_property: false,
        marking: false,
        traceability: false,
        details_measure_unit_digit: null,
        intellectual_code: null
      },
      preferences_1: [],
      preferences_2: [],
      preferences_3: [],
      preferences_4: [],
      xsdUpdate: null
    };
  },
  computed: {
    ...mapGetters({
      selected: "kdt/getSelected",
      selectedWares: "kdt/getSelectedWares",
      countries: "catalogs/getNsiCountries",
      measureUnits: "catalogs/getNsiMeasureUnits",
      nsiTariffs: "catalogs/getNsiTarifs",
      requests: "kdt/getRequests",
      disabledView: "kdt/getVisibility",
      xsdVersion: "catalogs/getXsdVersion"
    }),
    totalValue() {
      return this.total();
    },
  },
  watch: {
    show(value) {
      if (value === false) this.activeComponent = null;
    },
    selectedWares: {
      handler() {
        if (this.selectedWares.length) this.setFields();
      },
      deep: true,
    },
    sortIndex: {
      handler() {
        this.setFields();
      },
      immediate: true,
    },
  },
  created() {
    this.loadPreferences();
    eventBus.$on(WD_TNVED, this.onTnvedCodeSelect);
    eventBus.$on(WD_ADD_TNVED, this.onAddTnvedCodeSelect);
  },
  mounted() {
    this.setEventListeners();
  },
  beforeDestroy() {
    eventBus.$off(WD_TNVED, this.onTnvedCodeSelect);
    eventBus.$off(WD_ADD_TNVED, this.onAddTnvedCodeSelect);
  },
  methods: {
    getReferenceAsync,
    waitAsyncDecision,
    waitResultOrDie,
    copyItem() {
      this.$store
        .dispatch("kdt/copyWare", { id: this.id })
        .then(() => {
          this.$snackbar({
            text: "Товар скопирован",
            top: false,
            right: false,
          });
        })
        .catch((err) =>
          this.$error(`Не удалось скопировать товар. ${err.response.data}`)
        );
    },
    createWare() {
      Promise.all(this.requests)
        .then(() => {
          return this.$store.dispatch("kdt/addNewGoodsItem");
        })
        .then(() => this.$success("Добавлен товар"))
        .catch(() => this.$error());
    },
    showConfirm() {
      this.activeComponent = ConfirmAction;
      this.show = true;
      return this.waitAsyncDecision();
    },
    confirmRemoveWare() {
      this.showConfirm()
        .then(this.waitResultOrDie)
        .then(this.removeWare)
        .then(this.navigateToFirst)
        .catch(() => {});
    },
    removeWare() {
      return this.$store
        .dispatch("kdt/deleteGoodsItem", {
          id: this.id,
        })
        .then(() => this.$info("Товар удален"))
        .catch((error) => {
          this.$error("Ошибка удаления товара");
          throw error;
        });
    },
    navigateToFirst() {
      this.selectedWares.length
        ? this.$emit("navigate", 1)
        : this.$emit("close");
    },
    toggleTreeView() {
      this.tab = this.tab === 0 ? null : 0;
    },
    showCodeInfo,
    onAddTnvedCodeSelect({ code }) {
      this.item.add_tn_ved = code;
      this.readyToUpdate();
    },
    clearTnved() {
      this.item.tn_ved = "";
    },
    checkExtraTnved() {
      if (!this.item.tn_ved) {
        return this.clearTnved();
      }
      this.fillExtraTnved();
    },
    fillWithDefault(code) {
      this.item.add_tn_ved = code === "0000" ? "0000" : "";
    },
    fillExtraTnved() {
      const actionDate = moment().format("DD.MM.YYYY");
      const promise = new Promise((resolve, reject) => {
        this.$store
          .dispatch("catalogs/getExtraTnved", {
            code: this.item.tn_ved,
            actionDate,
          })
          .then((res) => {
            if (res.data?.length > 0) {
              const [element] = res.data;
              const { code } = element;
              this.fillWithDefault(code);
            } else {
              this.clearTnved();
            }
            resolve(res);
          })
          .catch((err) => reject(err));
      });
      this.promises.push(promise);
      return promise;
    },
    updateExciseQuantity() {
      if (!this.nsiTariffs.includes(this.item.tn_ved)) {
        this.item.excise_quantity = "";
      }
    },
    tnvedChanged() {
      this.checkExtraTnved();
      this.updateExciseQuantity();
      this.updateLatestCode();
    },
    onTnvedCodeSelect({ code }) {
      this.item.tn_ved = code;
      this.checkExtraTnved();
      this.readyToUpdate();
      this.updateLatestCode();
    },
    readyToUpdate() {
      this.fields_been_visited = true;
      this.hasChanges = true;
    },
    uploadIfUpdated() {
      if (this.hasChanges && this.fields_been_visited) {
        return this.uploadData()
          .then(() => this.$success("Обновлено"))
          .then(() => this.resetTriggers())
          .catch(() => this.$error());
      }
    },
    loadPreferences() {
      this.$store.dispatch("catalogs/fetchNsiPreferences").then((res) => {
        const allPreferences = res.data.list;
        const preferencesWithSearch =
          this.getPreferencesWithSearch(allPreferences);
        preferencesWithSearch.forEach((preference) => {
          this[`preferences_${preference.type}`].push(preference);
        });
      });
    },
    getPreferencesWithSearch(preferences) {
      return preferences.map((preference) => ({
        ...preference,
        search: `${preference.code}-${preference.name}`.toLowerCase(),
      }));
    },
    getCurrentWare() {
      return (
        this.selectedWares.find((ware) => ware.sort_index === this.sortIndex) ??
        {}
      );
    },
    setFields() {
      const ware = this.getCurrentWare();
      Object.keys(this.item).forEach((key) => {
        if (key in ware) {
          this.item[key] = ware[key];
        }
      });
      this.updateLatestCode();      
      this.xsdUpdate = this.checkXsdDateUpdate()
    },
    updateLatestCode() {
      this.latestCode = this.item.tn_ved;
    },
    getCompleteWare() {
      return {
        ...this.getCurrentWare(),
        ...this.item,
        origin_country_name: getCountryNameByCode(
          this.countries,
          this.item.origin_country_letter
        ),
        origin_country_name_b: getCountryNameByCode(
          this.countries,
          this.item.origin_country_letter_b
        ),
        details_measure_unit_letter: getMeasureUnitShortName(
          this.measureUnits,
          this.item.details_measure_unit_digit
        ),
      };
    },
    total() {
      return [this.item.description, this.item.specific_description]
        .filter((v) => v)
        .join("; ").length;
    },
    uploadData() {
      const payload = this.getCompleteWare();
      return Promise.all(this.promises).then(() =>
        this.$store.dispatch("kdt/updateGoodsItem", payload)
      );
    },
    showExtraTNVED() {
      showCatalog.trigger({
        type: "tnved_extra",
        id: this.item.id,
        search: this.item.tn_ved,
        field: "ware/add_tn_ved",
        zIndex: 300,
        customEventName: WD_ADD_TNVED,
      });
    },
    showTNVED() {
      showCatalog.trigger({
        type: "tnved",
        id: this.id,
        search: this.item.tn_ved,
        zIndex: 300,
        customEventName: WD_TNVED,
      });
    },
    intellectualCodeChange(){
      this.intellectual_code = null
    },
    checkXsdDateUpdate() {
      const xsdUpdateDateList = this.xsdVersion.filter(item => {
        return (item.declarationType.toUpperCase() === "KDT")
      }) 
      const xsdUpdateDate = xsdUpdateDateList.length == 0 ? null : xsdUpdateDateList[0].dateUpdate

      return !(xsdUpdateDate == null || (xsdUpdateDate != null && this.selected.about_declaration.declaration_date < xsdUpdateDate))
    },
  },
};
</script>
<style scoped>
.bordered {
  background: #fff;
  border: 1px #6e6e6e solid;
  border-radius: 6px;
  margin-right: auto;
  column-gap: 5px;
}
.textarea-border-solid {
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  padding: 0px 50px 10px 10px;
}
</style>
